<template>
  <div>
    <div
      v-if="page.enabled"
      class="page--wrapper"
      v-bind:style="{
        'background-image': 'url(' + page.backgroundUrl + ')',
      }"
    >
      <loading
        :active.sync="isLoading"
        :can-cancel="true"
        :on-cancel="onCancel"
        :is-full-page="fullPage"
      ></loading>
      <div class="container container--no-margin">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-lg-1"></div>
          <div class="col-xs-12 col-sm-12 col-lg-10">
            <div class="page--form" v-if="!submitted">
              <div
                class="page-logo"
                v-bind:style="{
                  'background-image': 'url(' + page.logoUrl + ')',
                }"
              ></div>
              <div class="page--title">
                {{ page.title }}
              </div>
              <div>
                <p class="page--description">
                  {{ page.description }}
                </p>
              </div>
              <hr />
              <div class="container-fluid container--no-margin">
                <div class="row row--spacing">
                  <div class="col-xs-12 col-sm-12 col-lg-6">
                    <div class="page--input-label">Artist Name</div>
                    <input
                      type="text"
                      placeholder="Lukas Red"
                      name="title"
                      v-model="demo.artist_name"
                      class="input--text email login--input-bottom"
                    />
                  </div>
                  <div class="col-xs-12 col-sm-12 col-lg-6">
                    <div class="page--input-label">Track Title</div>
                    <input
                      type="text"
                      placeholder="Live The Moment"
                      name="title"
                      v-model="demo.track_name"
                      class="input--text email login--input-bottom"
                    />
                  </div>
                </div>
                <div class="row row--spacing">
                  <div class="col-xs-12 col-sm-12 col-lg-12">
                    <div class="page--input-label">Email</div>
                    <input
                      type="text"
                      placeholder="lukasred@deepwinder.com"
                      name=""
                      v-model="demo.artist_email"
                      class="input--text email login--input-bottom"
                    />
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-lg-12">
                      <div class="page--input-label">How would you like to deliver your demo?</div>
                      <select class="input--select" v-model="type">
                        <option value="soundcloud">Soundcloud</option>
                        <option value="dropbox">Dropbox</option>
                        <option value="file">MP3 / WAV</option>
                      </select>
                  </div>
                </div>
                <hr />
                <div class="row row--spacing" v-if="type == 'soundcloud'">
                  <div class="col-xs-12 col-sm-12 col-lg-12">
                    <div class="page--input-label">Soundcloud URL</div>
                    <input
                      type="text"
                      v-model="demo.soundcloud_url"
                      placeholder="https://soundcloud.com/trackname"
                      name=""
                      class="input--text email login--input-bottom"
                    />
                  </div>
                </div>
                <div class="row row--spacing" v-if="type == 'dropbox'">
                  <div class="col-xs-12 col-sm-12 col-lg-12">
                    <div class="page--input-label">Dropbox URL</div>
                    <input
                      type="text"
                      v-model="demo.dropbox_url"
                      placeholder="https://soundcloud.com/trackname"
                      name=""
                      class="input--text email login--input-bottom"
                    />
                  </div>
                </div>
                <div class="row row--spacing" v-if="type == 'file'" >
                  <div class="col-xs-12 col-sm-12 col-lg-12">
                    <div id="drop_zone"  v-if="!files[0]" class="input--drop" v-cloak @drop.prevent="addFile" @dragover.prevent>
                      <p class="input--drop-text"><strong>Choose a file</strong> or drag it here..</p>
                      <svg version="1.1" class="input--drop-icon"
                       viewBox="0 0 408 408" style="enable-background:new 0 0 408 408;" width="75" xml:space="preserve">
                    <g>
                      <g>
                        <path d="M332,121.921H184.8l-29.28-34.8c-0.985-1.184-2.461-1.848-4-1.8H32.76C14.628,85.453,0,100.189,0,118.321v214.04
                          c0.022,18.194,14.766,32.938,32.96,32.96H332c18.194-0.022,32.938-14.766,32.96-32.96v-177.48
                          C364.938,136.687,350.194,121.943,332,121.921z"/>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path d="M375.24,79.281H228l-29.28-34.8c-0.985-1.184-2.461-1.848-4-1.8H76c-16.452,0.027-30.364,12.181-32.6,28.48h108.28
                          c5.678-0.014,11.069,2.492,14.72,6.84l25,29.72H332c26.005,0.044,47.076,21.115,47.12,47.12v167.52
                          c16.488-2.057,28.867-16.064,28.88-32.68v-177.48C407.957,94.1,393.34,79.413,375.24,79.281z"/>
                      </g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    </svg>
                    </div>
                    
                    <div class="overview--files">
                    <ul>
                      <li v-for="file in files" v-bind:key="file">
                        {{ file.name }} ({{ file.size | kb }} kb) <button @click="removeFile(file)" title="Remove">x</button>
                      </li>
                    </ul>
                    </div>
                  </div>
                </div>
                <hr />
                <div class="row row--spacing" >
                  <div class="col-xs-12 col-sm-12 col-lg-12">
                    <button
                      class="submit-button"
                      v-bind:style="{
                        background: page.primaryColor.hex,
                      }"
                      @click="submit()"
                    >
                      Submit
                    </button>
                    <a href="https://demobottle.com"
                      ><img src="/img/Powered.png" class="form--powered"
                    /></a>
                  </div>
                </div>
              </div>
            </div>
            <div class="page--form" v-if="submitted">
              Your demo is submitted to {{ page.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>Oops, this label isn't accepting demo's right now!</div>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "page",
  title() {
    return `Submit your track — ${this.page.name}`;
  },
  components: {
    Loading,
  },
  data() {
    return {
      page_id: this.$route.params.id,

      isLoading: false,
      fullPage: true,

      demo: {},
      submitted: false,
      page: null,
      
      type: "soundcloud",
      files:[]
    };
  },
  mounted() {
    var self = this;
    this.isLoading = true;
  
    axios({
      method: "GET",
      crossdomain: true,
      headers: { "Content-Type": "multipart/form-data" },
      url: "https://api.demobottle.com/demo/page/" + self.page_id,
    })
      .then(function (response) {
        self.page = response.data;
        self.page = self.trueCopy(self.page);
        self.isLoading = false;
        console.log(self.page);
      })
      .catch(function (error) {
        self.errors = error.response.data;
        self.isLoading = false;
      });
    self.isLoading = false;
  },
  methods: {
    submit() {
      console.log(this.demo);
      var self = this;
      this.isLoading = true;
      
      var formData = new FormData();
      formData.append("track_name", this.demo.track_name);
      formData.append("artist_name", this.demo.artist_name);
      formData.append("artist_email", this.demo.artist_email);
      
      if(this.type == "file"){
        formData.append('demo', this.files[0]);
      }else if(this.type == "soundcloud") {
        formData.append('soundcloud_url', this.demo.soundcloud_url);
      }else if(this.type == "dropbox") {
        formData.append('dropbox_url', this.demo.dropbox_url);
      }
      
      
      axios.post("https://api.demobottle.com/demo/page/submit/" + self.page_id,formData)
        .then(function (result) {
          console.log(result);
          self.isLoading = false;
          self.submitted = true;
        }, function (error) {
          self.errors = error.response.data;
          self.isLoading = false;
        });
    },
   addFile(e) {
     let droppedFiles = e.dataTransfer.files;
     if(!droppedFiles) return;
     ([...droppedFiles]).forEach(f => {
       this.files.push(f);
     });
   },
   removeFile(file){
     this.files = this.files.filter(f => {
       return f != file;
     });      
   },
  },
};
</script>


<style lang="scss">
</style>
